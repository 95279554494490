import _ from 'lodash';
import { getRendererByFieldType, getDataEditorByFieldType } from '../renderers/getByType';
import HeaderRenderer from '../renderers/HeaderRenderer';
import { withFieldIdentifier } from '../renderers/FieldIdentifier';
import { memoize } from '../../../../utils/cache';

export const isReadOnly = ({ dataType, custom, value, userEdited }) => {
  // NOTE - Do not love the separation of this logic, but custom fields were introduced very late in the game.
  const isGivenOrUserEditedNonCustomField =
    _.toLower(dataType) === 'given' || (!!value && !userEdited);
  const isCustomNonRequestedField = custom && value !== '~' && !userEdited;
  return isGivenOrUserEditedNonCustomField || isCustomNonRequestedField;
};

export const transformFieldLabel = label => _.camelCase(label);

const extractFieldThresholds = (fieldThresholds, fieldId) => {
  let min;
  let max;
  if (fieldThresholds) {
    const threshold = _.find(fieldThresholds, f => _.get(f, 'fieldId.value') === fieldId);
    min = _.get(threshold, 'min.value');
    max = _.get(threshold, 'max.value');
  }
  return { min, max };
};

export function orderFields(fields, ignoreDataTypeInSorting = false) {
  const customFieldsGroupedByRelatedTypeAndOrder = _.chain(fields)
    .filter(_.property('custom'))
    .groupBy(_.property('relatedTypeAndOrder.value'))
    .value();

  // Watch out, this returns a lodash object on which we must call .value() to be able to use
  return _.chain(fields)
    .compact()
    .reject(_.property('custom'))
    .reject(({ dataType }) => _.toLower(_.get(dataType, 'value')) === 'not applicable')
    .sortBy(({ order, dataType }) => {
      const dataTypeValue = _.get(dataType, 'value');
      const orderValue = _.get(order, 'value', _.size(fields) + 1);
      // NOTE - Not in love with this, we should do this at the service level in the future.
      if (ignoreDataTypeInSorting) {
        return orderValue;
      } else {
        switch (_.toLower(dataTypeValue)) {
          case 'given':
            return orderValue;
          case 'give or requested':
            return orderValue + 1000;
          default:
            return orderValue + 10000;
        }
      }
    })
    .map(field => {
      const customFields = customFieldsGroupedByRelatedTypeAndOrder[_.get(field, 'fieldId.value')];
      if (!_.isEmpty(customFields)) {
        return _.concat([field], customFields);
      } else {
        return field;
      }
    })
    .flatten();
}

export const fieldsToHeader = memoize(({ fields, width, ignoreDataTypeInSorting = false }) =>
  orderFields(fields, ignoreDataTypeInSorting)
    .map(
      ({ custom, customHeader, fieldId, fieldName, relatedTypeAndOrder, units, columnWidth }) => ({
        custom,
        value: _.get(fieldName, 'value'),
        fieldId: _.get(fieldId, 'value'),
        fieldName: _.get(fieldName, 'value'),
        customHeader: custom ? _.get(customHeader, 'value') : undefined,
        relatedTypeAndOrder: _.get(relatedTypeAndOrder, 'value'),
        units: _.get(units, 'value'),
        readOnly: true,
        valueViewer: HeaderRenderer,
        width: _.get(columnWidth, 'value', width),
      }),
    )
    .value(),
);

// This will need to get broken up, it does too many things at once.
export function fieldsAndRecordToRow({
  fields,
  record,
  fieldThresholds,
  width,
  ignoreDataTypeInSorting = false,
}) {
  const indexedByFieldId = _.keyBy(record, 'fid');
  const bidEnabled = _.get(record, 'bid.value');
  return orderFields(fields, ignoreDataTypeInSorting)
    .map(field => {
      const {
        fieldId,
        fieldName,
        dataType,
        fieldType,
        multiChoiceOptions,
        columnWidth,
        custom,
        relatedTypeAndOrder,
      } = field;
      const fieldIdValue = _.get(fieldId, 'value');
      const value = _.get(indexedByFieldId, [fieldIdValue, 'value'], '');
      const userEdited = _.get(indexedByFieldId, [fieldIdValue, 'userEdited'], false);
      const required = _.get(indexedByFieldId, [fieldIdValue, 'required'], 0);
      const thresholds = extractFieldThresholds(fieldThresholds, fieldIdValue);
      const errorMessage = _.get(indexedByFieldId, [fieldIdValue, 'error']);
      const error = bidEnabled && errorMessage ? errorMessage : undefined;
      const className = bidEnabled && errorMessage ? 'error' : undefined;
      const readOnly = isReadOnly({
        dataType: _.get(dataType, 'value'),
        custom,
        userEdited,
        value,
      });
      const valueViewer = getRendererByFieldType({ fieldType });
      const dataEditor = getDataEditorByFieldType({
        fieldType,
        multiChoiceOptions,
      });
      const rawCell = {
        recordId: _.get(record, 'id'),
        fieldId: _.get(fieldId, 'value'),
        fieldName: _.get(fieldName, 'value'),
        fieldType: _.get(fieldType, 'value'),
        relatedTypeAndOrder: _.get(relatedTypeAndOrder, 'value'),
        className,
        readOnly,
        userEdited,
        required,
        value,
        custom,
        error,
        ...thresholds,
        valueViewer: withFieldIdentifier({ fieldId: _.get(fieldId, 'value') })(valueViewer),
        dataEditor: dataEditor
          ? withFieldIdentifier({ fieldId: _.get(fieldId, 'value') })(dataEditor)
          : dataEditor,
        width: _.get(columnWidth, 'value', width),
      };

      // Remove undefined properties
      const clean = _.omitBy(rawCell, _.isUndefined);
      return clean;
    })
    .value();
}

export function disableRowsOnPredicate(rows, predicate = _.constant(false)) {
  return _.map(rows, row => {
    if (predicate(row)) {
      return _.map(row, item => ({
        ...item,
        readOnly: true,
      }));
    } else {
      return row;
    }
  });
}

export function removeEmptyColumns(rows, filter = _.constant(true)) {
  return _.map(rows, row =>
    _.reject(row, (item, idx) => {
      const allSubrowsEmptyNeverEdited = _.every(rows, subRow => {
        const { value, userEdited } = _.get(subRow, idx, {});
        return !value && !userEdited;
      });
      const filtered = filter(item);
      return allSubrowsEmptyNeverEdited && filtered;
    }),
  );
}

export function matchHeaderToColumns({ header, content }) {
  const firstRow = _.first(content);
  const indexedColumns = _.keyBy(firstRow, 'fieldName');
  return _.filter(
    header,
    ({ value, fieldName, custom }) =>
      _.get(indexedColumns, value) || (custom && _.get(indexedColumns, fieldName)),
  );
}

export const trimAndRemoveLeadingTilde = value => {
  if (typeof value === 'string') {
    const trimmed = value.trim();
    return trimmed === '~' ? '' : trimmed;
  } else {
    return value;
  }
};

export function transformRecordFields(record, targetFields, includeEmpty = false) {
  const indexedByFieldId = _.keyBy(record, 'fid');
  return orderFields(targetFields)
    .reduce((newRecord, field) => {
      const quickbaseRecordField = indexedByFieldId[_.get(field, 'fieldId.value')];
      const value = trimAndRemoveLeadingTilde(_.get(quickbaseRecordField, 'value'));
      if ((value !== undefined && value !== '') || includeEmpty) {
        newRecord[_.get(field, 'fieldName.value')] = quickbaseRecordField;
      }
      return newRecord;
    }, {})
    .value();
}

export function escapeForCSV(value) {
  return typeof value === 'string' ? `"${value}"` : value; // Wrap strings in double quotes to escape commas
}

export const applyRecordMappings = rules => record => {
  if (rules) {
    return rules.reduce((newRecord, rule) => {
      if (rule.constructor === Array) {
        newRecord[rule[1]] = record[rule[0]];
      } else {
        newRecord[_.upperFirst(rule)] = record[rule];
      }
      return newRecord;
    }, {});
  } else {
    return record;
  }
};

export const getRecordsHeaders = records =>
  records.reduce(
    (headers, record) =>
      _.concat(
        headers,
        _.keys(record).filter(key => !~headers.indexOf(key)),
      ),
    [],
  );

export function recordstoCSV(records, mappingRules) {
  const mappedRecords = records.map(applyRecordMappings(mappingRules));
  const headers = getRecordsHeaders(mappedRecords);
  const data = mappedRecords.map(record =>
    headers
      .map(header =>
        record[header] && typeof record[header] === 'object'
          ? record[header].type === 'file'
            ? _.get(record[header], 'value.url', '')
            : record[header].value
          : record[header],
      )
      .map(escapeForCSV)
      .join(','),
  );
  return [headers.join(','), ...data].join('\n');
}
