import _ from 'lodash';
import { memoize } from './cache';

export const alwaysParentheses = memoize(value => {
  const trimmed = _.trim(value);
  if (/^\(.*\)$/.test(value)) {
    return trimmed;
  } else if (/^\(/.test(value)) {
    return `${trimmed})`;
  } else if (/\)$/.test(value)) {
    return `(${trimmed}`;
  } else {
    return `(${trimmed})`;
  }
});

export const withoutSlashes = name => {
  const index = name.search(/(?<=.*)\/(?!.*\/)/g);
  return ~index ? name.substr(index + 1) : name;
};

export const withoutTimestamp = name => {
  const index = name.search(/(?<=[0-9]*)-/);
  return ~index ? name.substr(index + 1) : name;
};

export const withoutQuery = name => {
  const index = name.search(/\?/g);
  return ~index ? name.substr(0, index) : name;
};

export function getUrlPath(url) {
  return withoutQuery(withoutTimestamp(withoutSlashes(url)));
}
