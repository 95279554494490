import React from 'react';
import _ from 'lodash';
import { orderFields } from './common';
import HeaderRenderer from '../renderers/HeaderRenderer';
import { getRendererByFieldType } from '../renderers/getByType';
import { VerticallyCenteredRightAlignedCell } from '../../../../components/common/TableLayout';

export const valueRenderer = cell => _.get(cell, 'value');

const getKeyValueViewer = () => ({ cell }) => {
  const { value } = cell;
  return <VerticallyCenteredRightAlignedCell>{value}</VerticallyCenteredRightAlignedCell>;
};

const getValueValueViewer = ({ fieldType }) => data => {
  return getRendererByFieldType({ fieldType }, { rightAligned: false })(data);
};

// NOTE - We shouldn't need this method but have the occasional miss in the field mapping.
const getValueForFieldId = ({ indexedByFieldId, fieldId, fieldName }) => {
  const value = _.get(indexedByFieldId, [_.get(fieldId, 'value'), 'value'], '');
  if (_.isEmpty(value) && _.get(fieldName, 'value')) {
    return _.get(
      _.find(indexedByFieldId, v => fieldName.value === v.name),
      'value',
      '',
    );
  }
  return value;
};

export function toDataSheetRows({ scenario, fields, width }) {
  const widths = {
    '0': width * 0.3,
    '1': width * 0.7,
  };
  const indexedByFieldId = _.keyBy(scenario, 'fid');
  const orderedFieldsWrapper = orderFields(fields);
  return _.concat(
    [
      // HEADER
      _.map(['Given', 'Value'], (value, idx) => ({
        value,
        readOnly: true,
        valueViewer: HeaderRenderer,
        width: widths[idx],
      })),
    ],
    // CONTENT
    orderedFieldsWrapper
      .map(({ fieldName, fieldId, fieldType }) => [
        {
          value: _.get(fieldName, 'value'),
          readOnly: true,
          valueViewer: getKeyValueViewer(),
          width: widths[0],
        },
        {
          value: getValueForFieldId({
            indexedByFieldId,
            fieldId,
            fieldName,
          }),
          readOnly: true,
          valueViewer: getValueValueViewer({ fieldType }),
          width: widths[1],
        },
      ])
      // NOTE - Removing blank values
      .reject(([a, b]) => !_.get(b, 'value')) // eslint-disable-line no-unused-vars
      .value(),
  );
}
