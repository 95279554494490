import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { VerticallyHorizontallyCenteredCell } from '../../../../components/common/TableLayout';

const DateReadOnlyRenderer = ({ value }) => {
  const date = value ? moment(new Date(value)) : value;
  return (
    <VerticallyHorizontallyCenteredCell>
      {_.result(date, 'isValid') ? date.format('MM/DD/YYYY') : value}
    </VerticallyHorizontallyCenteredCell>
  );
};

DateReadOnlyRenderer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DateReadOnlyRenderer.defaultProps = {
  value: null,
};

export default DateReadOnlyRenderer;
